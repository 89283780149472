<template>
  <div class="add-new-coupon">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('coupon.create')"
      :title="$t('coupon.create')"
      @submitPressed="submitPressed"
    />
    <coupons-form
      v-if="coupon"
      :couponObject="coupon"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CouponsForm from "./components/CouponsForm.vue";

export default {
  components: {
    TitleBar,
    CouponsForm,
  },
  data() {
    return {
      coupon: {
          typeSelect: {},
          variantSelect: {
              id: 'UNIVERSAL',
              label: this.$helper.getEnumTranslation('coupon_variant', 'UNIVERSAL', this.$i18n.locale ),
          }
      },
      action: null,
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
